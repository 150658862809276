$bestzantaclawyers-blue: #4c98b1;
$bestzantaclawyers-blue-light: #e9f9ff;
$bestzantaclawyers-orange: #f38620;
$bestzantaclawyers-grey: #7d7d7d;
$bestzantaclawyers-grey-light: #f3f3f3;

:export {
    blue: $bestzantaclawyers-blue;
    blueLight: $bestzantaclawyers-blue-light;
    orange: $bestzantaclawyers-orange;
    grey: $bestzantaclawyers-grey;
    greyLight: $bestzantaclawyers-grey-light;
}
