
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 50px 0;
    @apply mx-auto px-5 lg:max-w-7xl flex flex-col-reverse md:flex-row justify-between items-center;

    h2 {
        @apply text-2xl mb-4;
        color: $bestzantaclawyers-blue;
    }
    p {
        color: $bestzantaclawyers-grey;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        @include md {
            font-size: 18px;
        }
    }
}
